/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to bottom right, #0d1b2a, #1b263b, #415a77);
    color: #e0e1dd;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.app-container {
    text-align: center;
}


/* src/components/ProductSlider.css */

/* Carousel Container */
.product-slider {
    width: 90%; /* Increased width to make the carousel larger */
    max-width: 800px; /* Larger max-width for bigger screens */
    margin: 2rem auto;
    background: rgba(13, 27, 42, 0.8); /* Dark background matching the theme */
    padding: 2rem; /* Padding for a centered look */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* Slight shadow for depth */
}

/* Slide Styling */
.product-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Product Image Styling */
.product-image {
    width: 100%;
    max-width: 400px; /* Increased max-width for larger images */
    height: auto;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 10px rgba(240, 165, 0, 0.5); /* Glowing shadow effect */
    margin-bottom: 1rem;
}

/* Product Name Styling */
.product-name {
    font-size: 1.8rem; /* Larger font size for prominence */
    color: #f0a500; /* Theme color */
    margin: 0.5rem 0;
}

/* Product Description Styling */
.product-description {
    font-size: 1.1rem; /* Increased font size */
    color: #a1c6ea; /* Light blue color to match theme */
    padding: 0 1rem;
    max-width: 80%; /* Limited width for readability */
}

/* General App Styles */
.app-container {
    font-family: Arial, sans-serif;
    color: #e0e1dd;
    background-color: #0d1b2a;
    margin: 0;
    padding-top: 70px; /* Offset for fixed navbar */
}

/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: rgba(13, 27, 42, 0.6);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Navbar Particle Containers with Lines */
.particle-container {
    width: 180px;
    height: 50px;
    opacity: 0.9;
    filter: drop-shadow(0 0 50px #f0a500);
    transition: filter 0.3s ease, opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.particle-svg {
    width: 100%;
    height: 100%;
}

/* Node Styling for Navbar Particle Container */
.particle-node {
    fill: #f0a500;
    r: 10;
    transition: filter 0.3s ease, r 0.3s ease;
    filter: drop-shadow(0 0 8px #f0a500);
}

/* Connecting Line Styling for Navbar Particle Container */
.particle-line {
    stroke: #a1c6ea;
    stroke-width: 1.5;
    animation: lineGlow 5s infinite alternate;
}

/* Intense Glow Effect on Hover for Navbar Container */
.navbar:hover .particle-node {
    filter: drop-shadow(0 0 150px #f0e68c); /* Stronger, brighter glow */
    r: 12; /* Slightly larger node on hover */
}

/* Navbar Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin: 0;
    padding: 0;
    align-items: center;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    color: #e0e1dd;
    font-weight: 500;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease, filter 0.3s ease;
}

/* Hover Effect for Navbar Links */
.nav-links a:hover {
    color: #f0a500;
    filter: drop-shadow(0 0 10px #f0a500);
}

/* Hero Section */
.hero-section {
    padding: 4rem 1rem;
    background: rgba(13, 27, 42, 0.9);
    color: #e0e1dd;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid #415a77;
    position: relative;
}

/* Hero Logo with Lines */
.hero-logo-pattern {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto;
    margin-top: -30px; /* Position closer to navbar */
    opacity: 0.9;
    animation: fadeIn 1.5s ease-in;
}

.hero-logo-svg {
    width: 100%;
    height: 100%;
}

/* Hero Logo Node Styling */
.hero-logo-node {
    fill: #f0a500;
    r: 8;
    animation: pulse 2s infinite ease-in-out;
    filter: drop-shadow(0 0 12px #f0a500); /* Intense glow for visibility */
}

/* Hero Logo Line Styling */
.hero-logo-line {
    stroke: #a1c6ea;
    stroke-width: 1.5;
    animation: lineGlow 2s ease-in-out infinite;
    filter: drop-shadow(0 0 10px #a1c6ea);
}

/* Position nodes to create an interconnected triangle */
.hero-logo-node:nth-child(1) { cx: 40; cy: 130; }  /* Bottom-left node */
.hero-logo-node:nth-child(2) { cx: 140; cy: 130; } /* Bottom-right node */
.hero-logo-node:nth-child(3) { cx: 90; cy: 50; }   /* Top-center node */



/* Hero Heading and Subheading */
.hero-heading {
    font-size: 3.5rem;
    font-weight: 700;
    color: #f0a500;
    margin-bottom: 1rem;
    margin-top: 100px;
}

.hero-subheading {
    font-size: 1.5rem;
    font-weight: 400;
    color: #a1c6ea;
    margin-bottom: 2rem;
}

/* Product Section */
.product-section {
    padding: 3rem 1rem;
    text-align: center;
}

.section-heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #e0e1dd;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #f0a500;
    display: inline-block;
    padding-bottom: 0.5rem;
}

/* Centered Carousel Text and Image */
.product-slide {
    text-align: center;
}

.product-slide img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(240, 165, 0, 0.5);
    margin: 0 auto;
}

.product-name {
    font-size: 1.5rem;
    color: #f0a500;
    margin: 0.5rem 0;
    text-align: center;
}

.product-description {
    font-size: 1rem;
    color: #a1c6ea;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

/* Animations for Hero Logo Pattern */
@keyframes pulse {
    0%, 100% {
        r: 5;
    }
    50% {
        r: 8;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.9;
    }
}

/* Glow Animation for Lines */
@keyframes lineGlow {
    0%, 100% {
        stroke: #a1c6ea;
        filter: drop-shadow(0 0 10px #a1c6ea);
    }
    50% {
        stroke: #f0e68c;
        filter: drop-shadow(0 0 15px #f0e68c);
    }
}
